import React, {Suspense, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import Hotel from "../../assets/img/bg/an-img-01.png"
import Hotel1 from "../../assets/img/icon/fe-icon01.png";
import AboutUs3 from "../../assets/img/about-us/about-us-3.png";
import BGImg from "../../assets/img/bg/bdrc-bg.jpg";

const Main = () => {
    const location = useLocation();
    const {selectedRoomCategoryId, selectedRoomCategoryTitle} = location.state;

    let currentDate = new Date().toISOString().substring(0, 10);
    let adultOptions = [1, 2];

    const getNextDay = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
    
        return today.toISOString().substring(0, 10);
      };

    const [selectedAdultOption, setSelectedAdultOption] = useState(adultOptions[0]);
    const [selectedCheckInDate, setSelectedCheckInDate] = useState(currentDate);
    const [selectedCheckOutDate, setSelectedCheckOutDate] = useState(getNextDay());

    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-bg02">
                                <div className="section-title center-align">
                                    <h5>Enter the room details</h5>
                                    <h2>
                                        Book A Room
                                    </h2>
                                </div>
                                <form action="" method="post" className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                                <input type="date" id="checkin-date" name="date"
                                                       onChange={(e) => setSelectedCheckInDate(e.target.value)}
                                                       defaultValue={selectedCheckInDate}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-times-octagon"></i> Check Out
                                                    Date</label>
                                                <input type="date" id="checkout-date" name="date"
                                                       onChange={(e) => setSelectedCheckOutDate(e.target.value)}
                                                       defaultValue={selectedCheckOutDate}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-users"></i> Adults</label>
                                                <select name="adults" id="adults"
                                                        onChange={(e) => setSelectedAdultOption(e.target.value)}>
                                                    {adultOptions.map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>))}
                                                </select>
                                            </div>
                                        </div>

                    
                                        <div className="col-lg-12">
                                            <div className="slider-btn mt-15">
                                                <Link to="/registration" className="btn ss-btn" state={{
                                                    checkInDate: selectedCheckInDate,
                                                    checkOutDate: selectedCheckOutDate,
                                                    adults: selectedAdultOption,
                                                    selectedRoomCategoryId: selectedRoomCategoryId,
                                                    selectedRoomCategoryTitle: selectedRoomCategoryTitle
                                                }}>Proceed to Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Main;