import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BGImg1 from "../../assets/img/slider/slider_bg_01.png";
import BGImg2 from "../../assets/img/slider/slider_bg_02.png";
import BGImg3 from "../../assets/img/slider/slider_bg_03.png";
import BGVideo from "../../assets/img/bg/video-bg.png";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import AboutUs2 from "../../assets/img/about-us/about-us-2.png";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png";
import Hotel2 from "../../assets/img/icon/fe-icon04.png";
import Hotel3 from "../../assets/img/icon/fe-icon05.png";
import Hotel5 from "../../assets/img/icon/fe-icon07.png";
import Hotel6 from "../../assets/img/icon/fe-icon08.png";
import Resort from "../../assets/img/bg/an-img-02.png";
import pricing1 from "../../assets/img/bg/an-img-02.png";
import Play from "../../assets/img/bg/play-button.png";
import Blog from "../../assets/img/bg/an-img-06.png";
import Partner1 from "../../assets/img/partners/vodacom.png";
import Partner2 from "../../assets/img/partners/windhoek.png";
import Partner3 from "../../assets/img/partners/tigo.png";
import { config } from "../../Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUsContent from "../elements/AboutUsContent/Main";
import Moment from "react-moment";
import "moment-timezone";
import { Row, Col } from "reactstrap";

import {
  MdCoffee,
  MdWifi,
  MdDirectionsCar,
  MdAcUnit,
  MdApartment,
  MdOutlineLocalFlorist,
  MdHouse,
  MdCelebration,
  MdSportsBar,
  MdLocationCity,
  MdVolunteerActivism,
  MdLiquor,
} from "react-icons/md";
import AboutUs3 from "../../assets/img/about-us/about-us-3.png";

const Main = () => {
  let url = config.url.API_URL;

  const [yShow, setyShow] = useState();
  const [hShow, sethShow] = useState();
  const [animation, setanimation] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [roomCategoryList, setRoomCategoryList] = useState([]);
  const [roomCategoryImageList, setRoomCategoryImageList] = useState([]);
  const [blogsList, setBlogsList] = useState([]);
  const [error, setError] = useState(null);
  const [toggler, setToggler] = useState(false);
  const [activeImage, setActiveImage] = useState(1);
  const [images, setImages] = useState([
    "assets/img/gallery/room-img01.png",
    "assets/img/gallery/room-img02.png",
    "assets/img/gallery/room-img03.png",
    "assets/img/gallery/room-img04.png",
    "assets/img/gallery/room-img05.png",
    "assets/img/gallery/room-img06.png",
  ]);
  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 600,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const brand = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getNextDay = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);

    return today.toISOString().substring(0, 10);
  };


  let [value, setValue] = React.useState([null, null]);
  let currentDate = new Date().toISOString().substring(0, 10);

  const adultOptions = [1, 2];
  
  const [selectedAdultOption, setSelectedAdultOption] = useState(
    adultOptions[0]
  );

  const [selectedCheckInDate, setSelectedCheckInDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState(getNextDay());

  const [roomNumbersIsDisabled, setRoomNumbersIsDisabled] = useState(true);

  useEffect(() => {
    fetch(`${url}/api/v1/room_category_images/`)
      .then((res) => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setRoomCategoryList(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        }
      );
  }, []);

  useEffect(() => {
    fetch(`${url}/api/v1/blogs/`)
      .then((res) => res.json())
      .then(
        (data) => {
          console.log(blogsList);

          setIsLoaded(true);
          setBlogsList(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        }
      );
  }, []);

  console.log("checkin date", selectedCheckInDate);
  console.log("checkout date", selectedCheckOutDate);
  console.log("adults", selectedAdultOption);

  return (
    <>
      <main>
      <section id="home" className="slider-area fix p-relative">
          <Slider className="slider-active" {...home}>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{ backgroundImage: `url(${BGImg2})` }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          className={`${
                            animation && "animation" && "fadeInUp"
                          } `}
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          Quality, comfort and safety
                        </h2>

                        <div className="slider-btn mt-30 mb-105">
                          <Link
                            to="/contact"
                            className="btn ss-btn active mr-15"
                            data-animation="fadeInLeft"
                            data-delay=".4s"
                          >
                            Discover More{" "}
                          </Link>

                             <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            // className="video-i popup-video"
                            className="btn ss-btn active"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> 
                            Intro Video
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  backgroundImage: `url(${BGImg1})`,
                  background_size: "cover",
                }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          Enjoy A Luxury Experience
                        </h2>
               
                        <div className="slider-btn">
                          <Link
                            to="/contact"
                            className="btn ss-btn active mr-15"
                            data-animation="fadeInLeft"
                            data-delay=".4s"
                          >
                            Discover More{" "}
                          </Link>
                          <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            // className="video-i popup-video"
                            className="btn ss-btn active"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> 
                            Intro Video
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{ backgroundImage: `url(${BGImg3})` }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          className={`${
                            animation && "animation" && "fadeInUp"
                          } `}
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: "0.4s" }}
                        >
                          Come visit us today

                        </h2>

                        <div className="slider-btn">
                          <Link
                            to="/contact"
                            className="btn ss-btn active mr-15"
                            data-animation="fadeInLeft"
                            data-delay=".4s"
                          >
                            Discover More{" "}
                          </Link>
                          <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            // className="video-i popup-video"
                            className="btn ss-btn active"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: " 0.8s" }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i> 
                            Intro Video
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>

        <div id="booking" className="booking-area p-relative">
          <div className="container">
            <form action="#" className="contact-form">
              <div className="row align-items-center">
                <Row>
                  <Col xs={12} md={3} lg={3}>
                  <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-badge-check"></i> Check In Date
                        </label>
                        <input
                          type="date"
                          id="chackin"
                          name="date"
                          onChange={(e) =>
                            setSelectedCheckInDate(e.target.value)
                          }
                          defaultValue={selectedCheckInDate}
                        />
                      </div>
                  </Col>

                <Col xs={12} md={3} lg={3}>
                <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-times-octagon"></i> Check Out
                          Date
                        </label>
                        <input
                          type="date"
                          id="chackout"
                          name="date"
                          onChange={(e) =>
                            setSelectedCheckOutDate(e.target.value)
                          }
                          defaultValue={selectedCheckOutDate}
                        />
                      </div>

                </Col>

                <Col xs={12} md={3} lg={3}>
                <div className="contact-field p-relative c-name">
                        <label>
                          <i className="fal fa-users"></i> Adults
                        </label>
                        <select
                          name="adults"
                          id="adu"
                          onChange={(e) =>
                            setSelectedAdultOption(e.target.value)
                          }
                        >
                          {adultOptions.map((item, index) => (
                            <option key={index} value={item}>
                              {item} adults
                            </option>
                          ))}
                        </select>
                      </div>
                </Col>
  
                <Col xs={12} md={3} lg={3}>
                <div className="slider-btn">
                        <label>
                          <i className="fal fa-calendar-alt"></i>
                        </label>
                        <Link
                          to="/availability"
                          state={{
                            checkInDate: selectedCheckInDate,
                            checkOutDate: selectedCheckOutDate,
                            adults: selectedAdultOption,
                          }}
                          className="top-btn"
                        >
                          check rooms{" "}
                        </Link>
                      </div>
                </Col>
                </Row>
              </div>
            </form>
          </div>
        </div>

        <AboutUsContent />

        
        <section
          id="service-details2"
          className="pt-120 pb-90 p-relative"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-01">
            <img src={Hotel2} alt="an-img-01" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>Explore</h5>
                  <h2>The Hotel Features</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel1} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <MdHouse size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details"> Modern and comfortable rooms</Link>
                    </h3>
                    <p>
                    Our rooms are designed to offer a perfect blend of modern style and comfort, featuring sleek, contemporary furniture that ensures a relaxing and luxurious stay. 
                    Each room is thoughtfully positioned to provide stunning views of our beautifully landscaped garden or the vibrant street scenes beyond. 
                    Whether you're looking to unwind amidst serene greenery or soak in the local ambiance, our rooms offer the ideal setting for a truly enjoyable experience.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <MdVolunteerActivism size={70} />
                  </div>
                  <div className="services-08-thumb">
                    <MdVolunteerActivism size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Customer service you can trust</Link>
                    </h3>
                    <p>
                    We take great pride in delivering exceptional customer service, setting the highest standard in our industry. 
                    Our dedicated team is committed to providing personalized care and attention, ensuring that every guest's needs are met with professionalism, warmth, and efficiency. Whether you're staying with us for business or leisure, you can trust that we will go above and beyond to make your experience truly remarkable.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel2} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <MdOutlineLocalFlorist size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Beautiful scenes</Link>
                    </h3>
                    <p>
                    Located in a community steeped in vibrant culture and deep historical significance, your stay with us promises to be more than just a getaway—it’s an opportunity to create lasting memories.
                     From the unique local traditions to the historical landmarks that surround us, every moment will be enriched with experiences that will leave a lasting impression, making your visit truly unforgettable.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel3} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <MdLocationCity size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Accessibility</Link>
                    </h3>
                    <p>
                    Ideally situated in the heart of the city, CitiInn offers unparalleled convenience for travelers. We are just 10 km from the airport and a mere 5 km from the bustling City Center, making it easy for you to access key attractions, business hubs, and local landmarks. Whether you're here for business or leisure, our prime location ensures you’re always close to where you need to be.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel5} alt="img" />
                  </div>
                  <div className="services-08-thumb">
                    <MdSportsBar size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Restaurant & Bar</Link>
                    </h3>
                    <p>
                    Our restaurant is fully equipped to cater to your culinary needs, offering a diverse selection of delicious dishes and refreshing drinks. With a menu that features a wide variety of flavorful options, we take pride in serving you with the best, ensuring every meal is a delightful experience. Whether you're in the mood for local specialties or international cuisine, we are here to make your dining experience exceptional.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-08-item mb-30">
                  <div className="services-icon2">
                    <img src={Hotel6} />
                  </div>
                  <div className="services-08-thumb">
                    <MdCelebration size={70} />
                  </div>
                  <div className="services-08-content">
                    <h3>
                      <Link to="/service-details">Special Offers</Link>
                    </h3>
                    <p>
                    Come visit us and take advantage of our exclusive offers and discounts! Whether you're a new guest or a returning visitor, you’ll have the chance to enjoy special promotions designed to make your stay even more rewarding. 
                    Don't miss out on the opportunity to experience our hospitality while saving on your next visit. 
                    Make the choice today and we will take it from there.
                    </p>
                    <Link to="/service-details">
                      Read More <i className="fal fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="feature-area2 p-relative fix"
          style={{ background: "#f7f5f1" }}
        >
          <div className="animations-02">
            <img src={Resort} alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30 pl-30">
                <div className="feature-img">
                  <img src={AboutUs2} alt="img" className="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20">
                    <h5>Luxury Hotel</h5>
                    <h2>We offer the best for less.</h2>
                  </div>
                  <p>
                    Our hotel is designed to offer services at affordable rates.
                    We value our clients, and are always happy to see returning
                    faces. We create an exciting experience in our rooms and
                    facilities so you get the best out of everything.
                  </p>

                  <div className="slider-btn mt-15">
                    <Link to="/about" className="btn ss-btn smoth-scroll">
                      Discover More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="pricing"
          className="pricing-area pt-120 pb-60 fix p-relative"
        >
          <div className="animations-01">
            <img src={Hotel} alt="an-img-01" />
          </div>
          <div className="animations-02">
            <img src={pricing1} alt="contact-bg-an-01" />
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4 col-md-12">
                <div className="section-title mb-20">
                  <h5>We got you covered</h5>
                  <h2>Extra Services</h2>
                </div>
                <p>
                  Citi Inn has services reserved for guests. This compliments
                  the overall hotel experience.
                </p>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-box pricing-box2 mb-60">
                  <div className="pricing-head">
                    <h3>Laundry Services</h3>
                    <p>We go the extra mile in ensuring you feel clean</p>
                    <div className="price-count"></div>
                    <hr />
                  </div>
                  <div className="pricing-body mt-20 mb-30 text-left">
                    <ul>
                      <li>Garment Laundry</li>
                      <li>Garment Ironing</li>
                      <li>Garment Folding</li>
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <Link to="/contact" className="btn ss-btn">
                      Get Started <i className="fal fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-box pricing-box2 mb-60">
                  <div className="pricing-head">
                    <h3>Room Service</h3>
                    <p>Food and drinks at your doorstep</p>
                    <div className="price-count">
                      <h2></h2>
                    </div>
                    <hr />
                  </div>
                  <div className="pricing-body mt-20 mb-30 text-left">
                    <ul>
                      <li>Order delivery</li>
                      <li>On request room cleaning</li>
                      <li>Special No DISTURB services</li>
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <Link to="/contact" className="btn ss-btn">
                      Get Started <i className="fal fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="video"
          className="video-area pt-150 pb-150 p-relative"
          style={{
            backgroundImage: `url(${BGVideo})`,
            background_repeat: "no-repeat",
            background_position: "bottom",
            background_size: "cover",
          }}
        >
          <div className="content-lines-wrapper2">
            <div className="content-lines-inner2">
              <div className="content-lines2"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="s-video-wrap">
                  <div className="s-video-content">
                    <Link
                      to="#"
                      className="popup-video"
                      onClick={() => setyShow(true)}
                    >
                      <img src={Play} alt="circle_right" />
                    </Link>
                  </div>
                </div>
                <div className="section-title center-align text-center">
                  <h2>Take A Tour with us</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="booking pt-120 pb-120 p-relative fix">
          <div className="animations-01">
            <img src={Hotel} alt="an-img-01" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="contact-bg02">
                  <div className="section-title center-align">
                    <h5>A brief walk around</h5>
                    <h2 className="mb-7">CitiInn Intro</h2>
                  </div>

                  <p>
                    CitiInn offers well-appointed rooms with modern amenities
                    such as flat-screen TVs, high-speed Wi-Fi, and comfortable
                    bedding to ensure a restful night's sleep. Our friendly
                    staff is always on hand to provide exceptional service and
                    make your stay with us a memorable one.
                  </p>

                  <p>
                    Our hotel features a stylish lobby with comfortable seating
                    and a coffee bar, where guests can enjoy a hot cup of coffee
                    or tea while catching up on work or chatting with friends.
                  </p>

                  <p>
                    CitiInn's central location makes it the perfect base for
                    exploring the city. We are close to popular attractions,
                    including the National Museum of Tanzania, the Kivukoni Fish
                    Market, and the Dar es Salaam International Conference
                    Centre.
                  </p>

                  <p>
                    Whether you are visiting Dar es Salaam for business or
                    leisure, CitiInn is the perfect choice for your stay. Book
                    your room today and experience the comfort and convenience
                    of our hotel firsthand.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="booking-img">
                  <img src={AboutUs3} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="blog" className="blog-area p-relative fix pt-90 pb-90">
          <div className="animations-02">
            <img src={Blog} alt="contact-bg-an-05" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div
                  className="section-title center-align mb-50 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>Our Blog</h5>
                  <h2>Latest News</h2>
                </div>
              </div>
            </div>

            <Suspense fallback={<div>Loading ...</div>}>
              <div className="carousel-container">
                <Slider {...sliderSettings}>
                  {blogsList.map((item, index) => (
                    <div className="col-xl-4 col-md-6" key={index}>
                      <div className="single-services mb-30">
                        <div className="services-thumb">
                          <Link
                            to="#"
                            className="gallery-link popup-image"
                            onClick={() => {
                              setToggler(!toggler);
                              setActiveImage(1);
                            }}
                          >
                            <img src={item.image} alt="img" />
                          </Link>
                        </div>
                        <div className="services-content">
                          <div className="day-book">
                            <div className="date-home">
                              <Moment date={item.date_time_created} />
                            </div>
                          </div>
                          <h4>
                            <Link to="/room-details">{item.title}</Link>
                          </h4>
                          <p>{item.details}</p>
                          <div className="blog-btn mt-3">
                            <Link to="/blog-details">Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Suspense>
          </div>
        </section>

        <div
          className="brand-area pt-60 pb-60"
          style={{ background: "#f7f5f1" }}
        >
          <div className="container">
            <Slider className="row brand-active" {...brand}>
              <div className="col-xl-2">
                <div className="single-brand">
                  <img src={Partner1} alt="img" />
                </div>
              </div>
              <div className="col-xl-2">
                <div className="single-brand">
                  <img src={Partner2} alt="img" />
                </div>
              </div>
              <div className="col-xl-2">
                <div className="single-brand">
                  <img src={Partner3} alt="img" />
                </div>
              </div>
              <div className="col-xl-2">
                <div className="single-brand">
                  <img src={Partner1} alt="img" />
                </div>
              </div>
              <div className="col-xl-2">
                <div className="single-brand">
                  <img src={Partner2} alt="img" />
                </div>
              </div>
            </Slider>
          </div>
        </div>

      </main>

      {/* <FsLightbox toggler={toggler} sources={images} slide={activeImage} /> */}

      {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setyShow(false)}
                  ></button>

                  <iframe
                    title="intro"
                    className="mfp-iframe"
                    // src="//www.youtube.be/embed/Ouqpdw8ic6g?autoplay=1"
                    src="//https://youtube.com/shorts/GXtm-GXOyl0?autoplay=1"
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
      {hShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: "hidden" }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => sethShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    title="close"
                    className="mfp-iframe"
                    // src="//www.youtube.be/embed/Ouqpdw8ic6g?autoplay=1"
                    src="//www.youtube.be/embed/GXtm-GXOyl0?autoplay=1"
                    frameborder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Main;
