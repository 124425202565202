import React, {Suspense, useEffect, useState} from "react";
import {config} from "../../Constants";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import {Link, useLocation} from "react-router-dom";
import {LinearProgress, Box, Typography, Chip, Divider} from "@mui/material";
import {Alert, Container, Button, Row, Col} from 'reactstrap';

const Main = () => {
    let url = config.url.API_URL;
    
    const location = useLocation();
    const { checkInDate = '', checkOutDate = '', adults = 1 } = location.state || {}; // fallback values

    const [isLoaded, setIsLoaded] = useState(false);
    const [availableVacantRoomCategoryList, setAvailableVacantRoomCategory] = useState([]);
    const [message, setMessage] = useState(""); 
    const [messageColor, setMessageColor] = useState("");
    

    useEffect(() => {
        fetch(`${url}/api/v1/get_vacant_room_categories/`)
            .then(res => res.json())
            .then((data) => {
                setAvailableVacantRoomCategory(data);
                setIsLoaded(true);
            }, (error) => {
                setIsLoaded(true);
            })
    }, []);

    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                <Container component="available-rooms">
                    <Box sx={{
                        marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}>
                        <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="contact-bg02">

                                        <Box sx={{flexDirection: 'column'}}>
                                            <Typography variant="h6">
                                                Checkin Date: {checkInDate || 'Not Available'}
                                            </Typography>
                                            <Typography variant="h6">
                                                Checkout Date: {checkOutDate || 'Not Available'}
                                            </Typography>
                                        </Box>

                                        <Row>
                                            <Alert color={messageColor}>
                                                    {message}
                                            </Alert>
                                        </Row>

                                        <Divider variant="middle">
                                            <Chip className="primary"
                                                  label="Select your rooms and add occupants"></Chip>
                                        </Divider>

                                        {(!isLoaded) ? (<LinearProgress/>) :
                                            (<Suspense fallback={<div>Loading...</div>}>
                                                {availableVacantRoomCategoryList.map((item, index) => (
                                                    <div key={index}>
                                                    <Row key={index} style={{marginTop:"20px"}}>
                                                        <Col xs={12} md={3}>
                                                            <img src={Hotel1}/>
                                                        </Col>

                                                        <Col xs={6} md={4}>
                                                            <Row>
                                                                <h4>{"Room Type: " + item.title} </h4>
                                                            </Row>
                                                            <Row>
                                                                <h4>{"Room Price: " + item.currency} {item.price.toLocaleString()}</h4>
                                                            </Row>
                                                        </Col>

                                                        <Col xs={6} md={2}>
                                                        <Row>
                                                            <h4>{"Rooms Left: " + item.room_count}</h4>
                                                            </Row>
                                           
                                                            <div className="mb-50">
                                                                <Link to="/bookings" state={{selectedRoomCategoryId:item.id }} className="btn ss-btn">Select Room</Link>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                    <Divider variant="middle">
                                                    <Chip className="primary"></Chip>
                                                    </Divider>
                                                    </div>
                                                ))}
                                            </Suspense>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Container>
            </section>
        </>
    )
}

export default Main;
