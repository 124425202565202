import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Hotel from "../../../assets/img/bg/an-img-01.png";
import { config } from "../../../Constants";
import { Alert, Container, Button, Row, Col } from "reactstrap";

const Main = () => {
  let url = config.url.API_URL;
  const location = useLocation();
  const {
    checkInDate,
    checkOutDate,
    adults,
    selectedRoomCategoryId,
    selectedRoomCategoryTitle,
  } = location.state;
  const clientBookingUrl = `${url}/api/v1/create_client_and_booking/`;
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [bookings, setBookings] = useState([]);

  const bookingPostBody = {
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    adults: adults,
    roomOccupants: {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      specialRequest: specialRequest,
      roomCategoryId: selectedRoomCategoryId,
    },
  };

  // Handle events
  const handleConfirm = () => {
    if (!showConfirmMsg) {
      setShowConfirmMsg(true);
    }
  };

  const handleClose = () => {
    setShowConfirmMsg(!showConfirmMsg)
  }

  const requestMetadata = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bookingPostBody),
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setSpecialRequest("");
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setShowConfirmMsg(!showConfirmMsg)

    fetch(clientBookingUrl, requestMetadata)
      .then((res) => res.json())
      .then(
        (data) => {
          // Handle successful response
          setSubmissionStatus(true);
          setBookings([]);

          if (data["status_code"] === 200) {
            setMessageColor("success");
            setMessage(
              "Room booked successfull. Your confirmation details have been sent to your email. "
            );
            setError(true);

            setTimeout(() => {
              setMessage("");
              setMessageColor("");
              setError(false);
            }, 2000);

            clearFields();
          } else if (data["status_code"] === 400) {
            setMessage("Error booking room");
            setMessageColor("danger");
            setError(true);

            setTimeout(() => {
              setMessage("");
              setMessageColor("");
              setError(false);
            }, 2000);
          }
        },
        (error) => {
          // Handle error response
          setMessage("Error booking room");
          setMessageColor("danger");
          setError(true);

          setTimeout(() => {
            setMessage("");
            setMessageColor("");
            setError(false);
          }, 2000);

          setSubmissionStatus(false);
        }
      );
  };

  return (
    <>
      <section className="booking pt-120 pb-120 p-relative fix">
        <div className="animations-01">
          <img src={Hotel} alt="an-img-01" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="contact-bg02">
                <div className="section-title center-align">
                  <h2>Booking Details</h2>
                </div>
                <div>
                  <Alert color={messageColor}>{message}</Alert>
                </div>
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  className="contact-form mt-30"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-name mb-20">
                        <label>
                          <i className="fal fa-badge-check"></i>First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-times-octagon"></i>Last Name
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-phone"></i> Phone Number
                        </label>
                        <input
                          type="phone"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-mailbox"></i> Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <label>
                          <i className="fal fa-comments"></i> Special Request
                        </label>
                        <input
                          type="textarea"
                          id="specialRequest"
                          name="specialRequest"
                          value={specialRequest}
                          onChange={(e) => setSpecialRequest(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="slider-btn mt-15">
                        <Button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          onClick={handleConfirm}
                          data-delay=".8s"
                        >
                          <span>Book Room Now</span>
                        </Button>
                      </div>
                    </div>

                    {showConfirmMsg && (
                    <div className="col-lg-6">
                        <br /> Are you sure want to save this booking? {" "}
                        <Button color="success" type="submit">
                          Yes
                        </Button>
                        <Button
                          className="btn btn-danger"
                          style={{ marginLeft: 3 }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
