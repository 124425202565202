import React from "react";
import {Link} from 'react-router-dom';
import Hotel from "../../assets/img/bg/an-img-01.png"

const Main =  () => {
    
    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                    <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="contact-bg02">
                                    <div className="section-title center-align">
                                        <h5>Check Booking</h5>
                                        <h4>
                                            Inorder to access your booking please provide the below details
                                        </h4>
                                    </div>
                                    <form action="mail.php" method="post" className="contact-form mt-30">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <label><i className="fal fa-badge-check"></i> Booking confirmation number</label>
                                                    <input type="text" id="booking-confirmation-number"/>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-subject mt-5">
                                                  <Link>I don't have this information.</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-subject mb-20">
                                                    <label><i className="fal fa-users"></i>First Name</label>
                                                    <input type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                     <div className="contact-field p-relative c-subject mb-20">
                                                    <label><i className="fal fa-users"></i>Last Name</label>
                                                    <input type="text"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="slider-btn mt-15">
                                                    <button className="btn ss-btn" data-animation="fadeInRight"
                                                        data-delay=".8s"><span>Find Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )

}

export default Main;